import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import { Box, Image, Flex, Text } from "@chakra-ui/react";
import tablet from "../assets/ContactTablet.png";
import {motion} from 'framer-motion'
import { fadeIn } from "../Variants";
function LeftSection() {
  const MotionBox = motion(Box)
  return (
    <>
      <Box width="30%" height="100vh">
        <Box
          bgColor="white"
          width="30%"
          position="fixed"
          zIndex={999}
          left={0}
          bottom={0}
          top={0}
        >
          <Box paddingY={3} paddingX={4}>
            <NavLink to="/">
              <Image
                h="auto"
                src={logo}
                alt="logo"
                width="auto"
                height="90px"
              />
            </NavLink>
            <Flex
              width="100%"
              // height="100vh"
              justifyContent="center"
              // alignItems="center"
            >
              <Image src={tablet} width="100%" />
            </Flex>
            <MotionBox
              variants={fadeIn({ direction: "left", delay: 0.6 })}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: false }}
            >
            <Text fontSize='20px' color="#18337A">
              We will provide a solution to your problems as soon as possible.
            </Text>
            </MotionBox>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LeftSection;
