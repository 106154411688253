import React from "react";
import LeftSection from "./LeftSection";
import ContactUs from "./ContactUs";
import { Box, Flex } from "@chakra-ui/react";

function Wrapper() { 
  return (
   <Flex>
      <Box width="30%"  display={{ base: "none", md: "block" }}>
        <LeftSection />
      </Box>
      <Box width={{base: '100%' ,md:"70%"}}> 
        <ContactUs />
      </Box>
   </Flex>
  );
}

export default Wrapper;
