import {
  Box,
  Flex,
  Input,
  FormControl,
  Button,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
function ContactUs() {
  return (
    <>
      <Box
        // width="0%"
        // height="100vh"
        bgColor="#eeeeee"
        overflowY="auto"
        padding={{
          base: "150px 20px 20px 20px",
          md: "150px 20px 30px 80px",
        }}
      >
        <FormControl action='https://formspree.io/f/xqkravqe' method='POST'>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems={{ base: "center", md: "start" }}
            gap={10}
          >
            <FormLabel width={{ base: "100%", md: "450px" }}>
              Share your question or problem and we will send an answer by email
            </FormLabel>

            {/* Input Name  */}
            <Flex
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={{ base: "center", md: "start" }}
              alignItems={{ base: "center", md: "start" }}
              gap={10}
            >
              <Flex
                flexDirection="column"
                width={{ base: "300px", md: "350px" }}
                gap={2}
              >
                <FormLabel fontSize="lg"> Name*</FormLabel>
                <Input
                  type="text"
                  placeholder="Name"
                  name="name"
                  bgColor="white"
                  border="none"
                  outline="none"
                />
              </Flex>
              {/* Input Email */}
              <Flex
                flexDirection="column"
                width={{ base: "300px", md: "350px" }}
                gap={2}
              >
                <FormLabel fontSize="lg"> Email*</FormLabel>
                <Input
                  type="email"
                  placeholder="Email"
                  name="Email"
                  bgColor="white"
                  border="none"
                  outline="none"
                />
              </Flex>
            </Flex>
            {/* Input Subject  */}
            <Flex
              flexDirection="column"
              width={{ base: "300px", md: "750px" }}
              gap={2}
            >
              <FormLabel fontSize="lg"> Subject*</FormLabel>
              <Input
                type="text"
                name="Subject"
                placeholder="Subject"
                bgColor="white"
                border="none"
              />
            </Flex>
            {/* FormLabel Area  */}
            <Flex
              flexDirection="column"
              width={{ base: "300px", md: "750px" }}
              gap={2}
            >
              <FormLabel fontSize="lg"> Report a problem*</FormLabel>
              <Textarea
                rows={5}
                placeholder="Write message"
                name="write"
                bgColor="white"
                border="none"
                outline="none"
              />
            </Flex>
          </Flex>
          <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "center", md: "space-between" }}
          paddingLeft={{ md: "10px" }}
          alignItems="center"
        >
          <Button colorScheme="blue" px={14} py={6} borderRadius={50} mt={10} type="submit">
            Send
          </Button>
          {/* Back Button */}
          <NavLink to='/'>
            <Button
              colorScheme="transparent"
              px={8}
              py={6}
              borderRadius={50}
              mt={10}
              position="relative"
              overflow="hidden"
              transition="0.5s ease"
              color="blue"
              fontSize={20}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </NavLink>
        </Flex>
        </FormControl>
      
      </Box>
    </>
  );
}

export default ContactUs;
