import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import { useNavigate, useLocation, NavLink } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const [isContactPage, setIsContactPage] = useState(false);

  useEffect(() => {
    setIsContactPage(location.pathname === "/contact");
  }, [location]);

  return (
    <Box px={20} bgColor={isContactPage ? "#eeeeee" : "none"}>
      <Flex
        py={20}
        justifyContent="space-evenly"
        direction={{ base: "column", md: "row" }}
        spacing={{ base: 2, md: 6 }}
      >
        <Box pb={4} width={{ base: "100%", md: "auto" }}>
          <NavLink to='/contact'>
            <Button
              px={{ base: 19, md: "65px" }}
              size="lg"
              colorScheme="blue"
              width="100%"
            >
              CONTACT US
            </Button>
          </NavLink>
        </Box>
        <Box pb={3} width={{ base: "100%", md: "auto" }}>
          <Button
            px={{ base: 19, md: "65px" }}
            size="lg"
            colorScheme="blue"
            width="100%"
          >
            DEALER INQUIRY
          </Button>
        </Box>
        <Box pb={4} width={{ base: "100%", md: "auto" }}>
          <Button
            px={{ base: 19, md: "65px" }}
            size="lg"
            colorScheme="blue"
            width="100%"
          >
            SOCIAL MEDIA
          </Button>
        </Box>
      </Flex>

      <Text
        textAlign="center"
        py="20px"
        fontSize={{ base: 12, md: 18 }}
        fontWeight={300}
        zIndex={99}
      >
        COPYRIGHT © 2024 AVID - ALL RIGHTS RESERVED.
      </Text>
    </Box>
  );
};

export default Footer;
