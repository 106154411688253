import React from 'react'
import ModelSection from '../../ModelDisplay/ModelSection'

function Model() {
  return (
    <div>
      <ModelSection/>
    </div>
  )
}

export default Model

