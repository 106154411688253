import React from "react";
import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls, OrbitControls } from "@react-three/drei";
import { Text } from "@chakra-ui/react";

function Modelview(props) {
  const { scene } = useGLTF("/tab.glb");
  return <primitive object={scene} scale={0.01} {...props} />;
}

function ModelSection() {
  return (
    <>
   
      <Canvas
        dpr={[1, 2]}
        shadows
        camera={{ position: [-5, 10, 0], fov: 45 }}
        style={{ height: "100vh" }}
      >
        <color attach="background" args={["#20428C"]} />
        <PresentationControls speed={1.5} zoom={0.5} polar={[-0.1, Math.PI + Math.PI / 4]}>
          <Stage environment={null}>
            <Modelview scale={0.01} />
          </Stage>
        </PresentationControls>
        <OrbitControls enablePan={false} enableZoom={false} />
      </Canvas>
    </>
  );
}

export default ModelSection;
