import React from 'react'
import Warpper from '../../Contact/Warpper'

function Contact() {
  return (
    <div>
      <Warpper/>
    </div>
  )
}

export default Contact
