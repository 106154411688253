import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../../Variants';
const Section = ({ mainText, subText, image, textPosition }) => {
  const MotionBox = motion(Box);
  const createMarkup = (text) => {
    return { __html: text };
  };

  return (
    <MotionBox
    variants={fadeIn({ direction: "up", delay: 0.6 })}
    initial="hidden"
    whileInView={"show"}
    viewport={{ once: false }}
    >
    <Flex
      paddingX={{ base: 10, md: 10, lg: 16 }}
      marginBottom={{md:20}}
      gap={10}
      direction={{ base: 'column', md: textPosition === 'right' ? 'row-reverse' : 'row' }}
      alignItems='center'
    >
      <Box flex={1} textAlign={{ base: 'center', md: 'left' }}>
        <Text
          fontSize={{ base: 30, md: 50, lg: 60 }}
          fontWeight={800}
          textTransform='uppercase'
        >
          {mainText}
        </Text>
        <Text
          fontSize={{ base: 18, md: 20, lg: 23 }}
          fontWeight={700}
          dangerouslySetInnerHTML={createMarkup(subText)}
        />
      </Box>
      <Box flex={1} >
        {/* <img src={image} style={{filter:'drop-shadow(5px 5px 5px #222)'}} alt='none'/> */}
        <Image margin='auto' w={'70%'} src={image} filter={'drop-shadow(3px 3px 12px #C5C5C5)'} />
      </Box>
    </Flex>
    </MotionBox>
  );
}

export default Section;
