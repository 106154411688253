import carBreaked from '../../assets/carbreaked.png';
import phoneInHand from '../../assets/mobileInHand.png';
import car from '../../assets/car.png';
import guages from '../../assets/ContactTablet.png'
import diagontics from '../../assets/diagonstic.png'
let sections = {
  'guages': {
    mainText: 'Guages',
    subText: 'Fully customizable user interface with user programmable indicators so you know what is going on in your vehicle.',
    image: guages
  },
  'diagnostic': {
    mainText: 'Diagnostic',
    subText: `DTC and monitor status is only one a touch away with a full vehicle health report that can be shared with your specialist via the Arc.`,
    image: diagontics
  },
  'dataloging': {
    mainText: 'Dataloging',
    subText: `One touch industry leading data logging, right from your gauges screen so you can see Everything.`,
    image: carBreaked
  },
  'statecontrol': {
    mainText: 'State Control',
    subText: `Control high idle, fuel pump duty cycle, and more right from your device.`,    
    image: phoneInHand
  },
  'asbuilt': {
    mainText: 'As-Built',
    subText: 'Make changes to your vehicle configuration for tire sizes, bambi mode, upfits, and add-ons. Disable auto-start, enable one touch windows, and more!',
    image: carBreaked
  },
  'addons': {
    mainText: 'Add-Ons',
    subText: 'Accessories coming soon to Expand function ever further.',
    image: phoneInHand
  },
  'wifi': {
    mainText: 'Wi-Fi',
    subText: 'Built in wi-fi means you can connect with your specialist as well as receive updates via any network, including your phone`s hotspot.',
    image: phoneInHand
  },
  'beauty': {
    mainText: 'Beauty',
    subText: '5" 720P Display:<br/>Immerse yourself in stunning visuals on the crystal-clear 5" 720P Display, delivering vibrant colors and sharp details for an exceptional viewing experience.',
    image: carBreaked
  },
  'networking': {
    mainText: 'Networking',
    subText: 'Dual CAN Transceivers:<br/>Stay connected and in control with our advanced Dual CAN Transceivers, ensuring reliable communication and real-time data exchange for automotive applications.',
    image: car
  },
  'power': {
    mainText: 'Power',
    subText: `Tri-Core Cortex A7 MPU:<br/>Experience lightning-fast performance with our cutting-edge Tri-Core Cortex A7 MPU, ensuring seamless multitasking and swift execution of tasks.`,
    image: carBreaked
  },
  'space': {
    mainText: 'Space',
    subText: '4GB On-Board Storage:<br/>Store all your datalogs, calibrations, and configus with ease thanks to the generous 4GB on-board storage, providing ample space for your world.',
    image: car
  },
  'support': {
    mainText: 'Support',
    subText: 'We will support 2011+ Ford at launch, with more applications coming soon!',
    image: carBreaked
  },
  
};

export default sections;