import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from "@chakra-ui/react";
import tablet from "../../assets/tablet.png";
import arcLogo from "../../assets/arclogo.png";
import pmcTechImg from "../../assets/pmctech.png";
import carSectionbg from "../../assets/carSection.svg";
import superCarImage from "../../assets/superCar.png";
import Section from "./Section";
import sections from "./sections";
import AvidVedio from "../../media/AvidVedio.mp4.mp4";
import { motion } from "framer-motion";
import { fadeIn } from "../../Variants";
import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls, OrbitControls } from "@react-three/drei";
import guage from "../../assets/ContactTablet.png";
import { NavLink } from "react-router-dom";
// 3D object
function Modelview(props) {
  const { scene } = useGLTF("/tab.glb");
  return <primitive object={scene} scale={0.01} {...props} />;
}
const HomePage = () => {
  const MotionBox = motion(Box);

  return (
    <>
      <Box pb={16}>
        <Flex
          position="relative"
          alignItems="center"
          paddingX={{ base: 10, md: 10, lg: 20 }}
          h={{ base: "75vh", lg: "100vh" }}
          w="100%"
          backgroundImage="url(https://www.motoringresearch.com/wp-content/uploads/2022/04/001-Mustang-best-selling-sports-car-1920x1080.jpg)"
          bgSize="cover"
        >
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            backgroundColor="rgba(0, 0, 0, 0.7)"
          ></Box>
          <motion.div
            variants={fadeIn({ direction: "left", delay: 0.6 })}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false }}
          >
            <Grid
              mt={20}
              gridTemplateColumns={{ base: "1fr", md: " 1fr 1fr" }}
              zIndex={10}
            >
              <GridItem marginY="auto"  alignItems="center">
                <Text
                  fontSize={{ base: "40px", lg: "65px" }}
                  fontWeight="800"
                  color="white"
                  pos="relative"
                  zIndex={10}
                  marginBottom={{ base: "20px", lg: "100px" }}
                >
                  Coming QI 2024
                </Text>
                <NavLink to='/model'><Button
                  colorScheme="blue"
                  fontSize={{ base: "14px", md: "20px" }}
                  size="lg"
                >
                  Sign Up For Beta Testing
                </Button></NavLink>
              </GridItem>
              {/* Tablet Image */}
              <GridItem>
              <Box
                position="relative"
                // top="50%"
                // right="50px"
                // transform="translate(-50%, -50%)"
                width={{base: '250px',md:"350px" , lg: '500px'}} 
                height={{base: '300px',md:"600px"}}  
                ml={{base: '10px',md:'0px' , lg: '200px'}} 
                mt={{base:'20px', md:'0'}}
                zIndex={20}
              >
                <Canvas
                  dpr={[1, 2]}
                  shadows
                  camera={{ position: [-5, 10, 0], fov: 45 }}
                  // style={{ width: "500px", height: "100%" }}
                >
                  {/* <color attach="background" args={["transparent"]} /> */}
                  <PresentationControls
                    speed={1.5}
                    zoom={0.5}
                    polar={[-0.1, Math.PI + Math.PI / 4]}
                  >
                    <Stage environment={null}>
                      <Modelview scale={0.01} />
                    </Stage>
                  </PresentationControls>
                  <OrbitControls enablePan={false} enableZoom={false} />
                </Canvas>
              </Box>
              </GridItem>
            </Grid>
          </motion.div>
        </Flex>
        {/* Second Section is Start */}

        <Box>
          {/* <BackGroundAnimation/> */}
          <Flex direction="column" gap={{ base: "40px", md: 10 }} mt={12}>
            {/* Guages section */}
            <Box zIndex={99}>
              
                  <Section
                    mainText={sections.guages.mainText}
                    subText={sections.guages.subText}
                    image={sections.guages.image}
                    textPosition="left"
                  />
              
              {/* Diagnostic  */}
              <Section
                mainText={sections.diagnostic.mainText}
                subText={sections.diagnostic.subText}
                image={sections.diagnostic.image}
                textPosition="right"
              />
              {/* Car Section Start */}
              <motion.div
                variants={fadeIn({ direction: "up", delay: 0.6 })}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false }}
              >
                <Box
                  mx={{ base: 5, md: 20 }}
                  my={{ base: "10px", md: "100px" }}
                  // height={{ base: "", md: "350px", lg: "500px" }}
                  position="relative"
                >
                  {/* Car Section  */}
                  <Image
                    src={carSectionbg}
                    w="100%"
                    zIndex={"-1"}
                    position="absolute"
                    borderRadius="50px"
                  />

                  <Flex
                    py={{ base: 1, md: 3 }}
                    px={{ base: 2, md: 24 }}
                    pb={{ base: "90px", md: "150px" }}
                    direction="column"
                    borderRadius="50px"
                  >
                    <Image
                      mt={{ base: "3", md: "10" }}
                      pl={3}
                      width={{ base: "50px", md: "150px", lg: "240px" }}
                      src={arcLogo}
                    />
                    <Text
                      color="white"
                      fontWeight={{ base: 400, md: 700 }}
                      fontSize={{ base: "12px", md: "20px", lg: "28px" }}
                      my={3}
                    >
                      Upload datalogs and receive calibrations directly with
                      <br />
                      your specialist via the Arc using any Wi-Fi connection.
                    </Text>
                  </Flex>

                  {/* Main Car Img */}
                  <motion.div
                    variants={fadeIn({ direction: "up", delay: 1 })}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{ once: false }}
                  >
                    <Image
                      w={{ base: "50%", md: "50%" }}
                      src={superCarImage}
                      position="absolute"
                      bottom={{ base: 5, md: "-30px", lg: "-300px" }}
                      right="50px"
                    />
                  </motion.div>
                </Box>
              </motion.div>
              {/* DataLoging */}
              <Box mt={{ md: "400px" }}>
                <Section
                  mainText={sections.dataloging.mainText}
                  subText={sections.dataloging.subText}
                  image={sections.dataloging.image}
                  textPosition="right"
                />
              </Box>
              <Section
                mainText={sections.statecontrol.mainText}
                subText={sections.statecontrol.subText}
                image={sections.statecontrol.image}
                textPosition="left"
              />
              {/* Video Section */}
              <MotionBox
                mx={{ base: 5, md: 20 }}
                my="100px"
                variants={fadeIn({ direction: "up", delay: 0.6 })}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false }}
              >
                <Flex
                  py={6}
                  px={{ base: 5, md: 20 }}
                  bg="black"
                  gap={{ base: 5, md: 20 }}
                  direction={{ base: "column", md: "row" }}
                  borderRadius="3xl"
                  alignItems="center"
                >
                  <Box flex={1}>
                    <video mt="5" width={"110%"} autoPlay controls>
                      <source src={AvidVedio} type="video/mp4" />
                    </video>
                  </Box>
                  <Text
                    flex={0.8}
                    color="white"
                    fontWeight={700}
                    fontSize="30px"
                    my={8}
                  >
                    Powerfull calibration tools supporting ArcaneOS.
                  </Text>
                </Flex>
              </MotionBox>
              {/* AsBuilt */}
              <Section
                mainText={sections.asbuilt.mainText}
                subText={sections.asbuilt.subText}
                image={sections.asbuilt.image}
                textPosition="left"
              />
              {/* Wifi Section */}
              <Section
                mainText={sections.addons.mainText}
                subText={sections.addons.subText}
                image={sections.addons.image}
                textPosition="right"
              />
              <Section
                mainText={sections.wifi.mainText}
                subText={sections.wifi.subText}
                image={sections.wifi.image}
                textPosition="left"
              />
              {/* Beauty */}
              <Section
                mainText={sections.beauty.mainText}
                subText={sections.beauty.subText}
                image={sections.beauty.image}
                textPosition="right"
              />
              {/* Networking */}
              <Section
                mainText={sections.networking.mainText}
                subText={sections.networking.subText}
                image={sections.networking.image}
                textPosition="left"
              />
              {/* Power */}
              <Section
                mainText={sections.power.mainText}
                subText={sections.power.subText}
                image={sections.power.image}
                textPosition="right"
              />
              {/* Space */}
              <Section
                mainText={sections.space.mainText}
                subText={sections.space.subText}
                image={sections.space.image}
                textPosition="left"
              />
              {/* Support */}
              <Section
                mainText={sections.support.mainText}
                subText={sections.support.subText}
                image={sections.support.image}
                textPosition="right"
              />
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
