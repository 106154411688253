import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Image,
  Link,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Spacer,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import logo from '../assets/logo.png';
import { NavLink, useLocation } from 'react-router-dom';
const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [windowScrolled, setWindowScrolled] = useState(false);
// Contact text Color 
 const location = useLocation()
 const ContactPage = location.pathname === '/contact'


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setWindowScrolled(true);
      } else {
        setWindowScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
   
    <Box
      bgColor={ContactPage  ? '#eeeeee' :windowScrolled ? 'white' : 'transparent'}
      boxShadow={windowScrolled ? 'md' : 'none'}
      position='fixed'
      w='100%'
      top='0'
      zIndex='999'
      css={{
        transition: "all 0.5s ease"
      }}
    >
      
      <Grid
        templateColumns='1fr 1fr'
        paddingY={3}
        paddingX={{ base: 3, md: 6, lg: 10 }}
       
      >
        <Box width={{ base: '7rem', lg: '12rem' }}  paddingY={3}>
          <NavLink to='/'><Image h='auto' src={logo} alt='logo' /></NavLink>
        </Box>
        <Grid
          display={{ base: 'none', md: 'grid' }}
          templateColumns='repeat(5, 1fr)'
          alignItems='center'
          fontWeight='bold'
          color={ContactPage ? 'black' :windowScrolled ? 'black' : 'white'}
         
        >
         <Spacer/>
         <Spacer/>
          {/* <GridItem>
            <NavLink to='/'>Home</NavLink>
          </GridItem>
          <GridItem>
            <NavLink to='/about'>About Us</NavLink>
          </GridItem>
          <GridItem>
            <NavLink to='/contact'>Contact Us</NavLink>
          </GridItem> */}
        </Grid>
      
        <Flex 
          display={{ base: 'flex', md: 'none' }}
          justifyContent='flex-end'
        >
          {/* <IconButton 
            color={ContactPage ? 'black' : windowScrolled ? 'black' : 'white'}
            
            variant='outlined'
            icon={<HamburgerIcon />}
            onClick={onOpen}
          /> */}
        </Flex>
      </Grid>

      {/* Drawer for Mobile */}
      {/* <Drawer isOpen={isOpen} placement='left'   onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent >
          <DrawerCloseButton  />
          <DrawerHeader >
            <Image h='65%' src={logo} alt='logo'  />
          </DrawerHeader>
          <DrawerBody
            fontWeight='bold'
           
          >
            <Grid
             templateColumns='1fr'
             gap={6}
            >
            <NavLink to='/' display='block' py={4} >
              Home
            </NavLink>
            <NavLink to='/about' display='block' py={4} >
              About Us
            </NavLink>
            <NavLink to='/contact' display='block' py={4} >
              Contact Us
              </NavLink>
              </Grid>
          </DrawerBody>
        </DrawerContent>
      </Drawer> */}
    </Box>
  );
};

export default Header;
