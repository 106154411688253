import React, { useState, useEffect } from "react";
import { Text } from "@chakra-ui/react";
import { useSpring, animated } from "react-spring";

function BackGroundAnimation() {
  const [showText, setShowText] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
      setShowText(window.scrollY >= 650);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const springProps = useSpring({
    opacity: showText ? 1 : 0,
    top: showText ? "50%" : "0%",
    from: { opacity: 0, top: "0%" },
  });

  const textColors = [
    ["#18337A", "gray", "gray"], // First word green
    ["gray", "#18337A", "gray"], // First two words green
    ["gray", "gray", "#18337A"], // All words green
  ];

  const currentTextColors = textColors[
    Math.min(2, Math.floor(scrollPosition / 2200)) // Map scroll position to color array index
  ];

  return (
    <>
      <animated.div
      
        style={{
          ...springProps,
          width: "100%",
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
        
          // textAlign: "center",
          // transition: '0.3s ease',
        }}
      >
        <Text
          fontSize={{ base: "18px", md: "5xl" }}
          opacity={0.6}
          color="gray"
          fontWeight={700}
          transition="0.8s ease"
          paddingX={{base: '5px' , md: '60px'}}
        >
          <span style={{ color: currentTextColors[0]  }} >Smart.</span>
          <span style={{ color: currentTextColors[1] }}>Fast.</span>
          <span style={{ color: currentTextColors[2] }}>Everything you want it to be.</span>
        </Text>
      </animated.div>
    </>
  );
}

export default BackGroundAnimation;
