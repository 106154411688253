import Contact from '../src/pages/homePage/Contact'
import Footer from "./components/Footer";
import Header from "./components/Header";
import BackGroundAnimation from "./pages/homePage/BackGroundAnimation";
import HomePage from "./pages/homePage/HomePage";
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Model from '../src/pages/homePage/Model';

function App() {
  return (
    <div className="App">
     
      <Router>
      <Header />
     <BackGroundAnimation/>
        <Routes>
        <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
           <Route path='/model'  element = {<Model/>} />
        </Routes>
        <Footer />
      </Router>
     
    </div>
  );
}

export default App;
